/* eslint-disable react/jsx-pascal-case */
import { eids, golf } from './enums';
import { FormattedMessage } from '@godaddy/react-mintl';
import OrderConfirmation from '@ux/order-confirmation';
import Notifications from '@site/notifications';
import Help from '@ux/icon/help';
import Link from '@ux/link';
import Logo from '@ux/logo';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import AppName from './app-name';
import { User } from './account-tray';
import HamburgerMenu from './hamburger';
import classnames from 'classnames';

import '@ux/notifications/styles';

export const helpApps = ['help', 'how-to', 'community', 'system-status'];

/**
 * Render Version.
 *
 * @class Version
 * @api public
 */
class Version extends Component {
  render() {
    return <Fragment>{this.props.value}</Fragment>;
  }
}

Version.propTypes = {
  value: PropTypes.string
};

/**
 * Render Application Header Footer.
 *
 * @class NavigationTop
 * @api public
 */
export default class NavigationTop extends Component {
  constructor() {
    super(...arguments);

    this.logoTray = React.createRef();

    this.state = {
      shouldShowHelp: false,
      items: 0
    };
  }

  /**
   *
   * @returns {React.element} notifications component
   */
  renderNotifications() {
    const props = this.props;
    const { privateLabelId, urls } = props;

    const notificationMessages = Object.keys(golf.notifications).reduce(
      (memo, key) => {
        memo[key] = <FormattedMessage id={ golf.notifications[key] } />;
        return memo;
      },
      {}
    );

    return (
      <Notifications
        appKey={ props.app }
        id='Notifications'
        manifest={ props.manifest }
        sso={ urls.sso }
        market={ props.market }
        shopperId={ props.shopperId }
        privateLabelId={ privateLabelId }
        messages={ notificationMessages }
        tooltip={ props.messages[golf.notifications.headerText] }
        showCount={ false }
        showOpenCaret={ true }
        { ...urls.notifications }
      />
    );
  }

  /**
   * Assesses help variant treatment and assigns appropriate UI.
   * Reseller and mobile are rendered serverside.
   * In all other cases there is no serverside help link
   * so that it renders blank before the treatment is
   * chosen on client side
   * @returns {React.ReactElement} The li for the help link
   */
  // eslint-disable-next-line max-statements
  renderHelp() {
    const props = this.props;

    if (
      props.preset === 'internal-header' ||
      helpApps.indexOf(props.app) > -1
    ) {
      return null;
    }

    const isApiReseller = props.privateLabelType === 3;
    const isReseller = props.privateLabelId !== 1;
    let help;
    let label = <Help width={ 30 } height={ 30 } />;
    let linkTitle = props.messages[golf.inApp];
    let extraCssClass;

    if (!isApiReseller || (props.resellerUrls && props.resellerUrls.help)) {
      if (
        !props.isMobile &&
        !props.isTablet &&
        !isReseller &&
        props.market === 'en-US'
      ) {
        const id = golf.helpCenter;
        label = <FormattedMessage id={ id } />;
        linkTitle = props.messages[id];
        extraCssClass = 'help-text';
      }
      help = (
        <li key='help' className={ classnames('nav-top-item', extraCssClass) }>
          { this.renderHelpLabel({
            label,
            tooltip: linkTitle,
            url: props.helpUrl ? props.helpUrl : props.urls.help.href
          }) }
        </li>
      );
    }

    return help;
  }

  /**
   * Render help label
   * @returns {React.ReactElement} The anchor encompassing the help label (if not icon)
   */
  renderHelpLabel({ label, tooltip, url }) {
    if (!url) {
      return null;
    }

    const labelIsIcon = label.type === Help;

    let href = url;
    if (typeof window !== 'undefined' && window && window.location) {
      const urlObj = new URL(url);
      urlObj.searchParams.append('referrer', encodeURIComponent(window.location.href));
      href = urlObj.toString();
    }

    return (
      <Link
        href={ href }
        data-eid={ eids.helpCenter }
        data-tcc-ignore={ true }
        className='single-parent-link nav-link-help'
        title={ labelIsIcon ? tooltip : null }
        target='_blank'
      >
        { label }
      </Link>
    );
  }

  /**
   * Render help, cart, notifications, and account tray. ORDER MATTERS.
   * @returns {React.ReactElement[]} Array of components for each global element
   */
  renderGlobalElements() {
    const props = this.props;
    const loggedIn = props.loggedIn;
    const isInternal = props.preset === 'internal-header';
    const isPass = props.preset === 'pass-header';
    const elements = [];
    const isApiReseller = props.privateLabelType === 3;

    elements.push(this.renderHelp());

    if (!isInternal && !isPass && !isApiReseller) {
      const Cart = props.CartComponent;

      elements.push(
        <li
          key='cart'
          className={ classnames(
            props.className,
            props.hidden,
            'nav-top-item'
          ) }>
          <Cart
            appKey={ props.app }
            items={ props.items }
            market={ props.market }
            messages={ props.messages }
            hideCheckoutNow={ true }
            showEmpty={ true }
            checkout={ props.cartUrl || props.urls.checkout.href }
            className='single-parent-link nav-link-cart'
          />
        </li>
      );
    }

    if (loggedIn && !isPass && !isInternal && !isApiReseller) {
      elements.push(
        <li key='notifications' className='nav-top-item'>
          { this.renderNotifications() }
        </li>
      );
    }

    if (!isPass) {
      elements.push(
        <li key='account-tray' className='nav-top-item'>
          <User { ...props } />
        </li>
      );
    }

    return elements;
  }

  /**
   * Renders left nav elements: logo, home button, app name.
   * @returns {React.element} The div encompassing left nav elements
   */
  renderLeftNav() {
    const props = this.props;
    const {
      privateLabelId,
      isMobile,
      isTablet,
      orderId,
      messages,
      market,
      version,
      pcx,
      urls
    } = props;
    const isNotDesktop = isMobile || isTablet;
    const logoClasses = classnames({ 'logo-text': privateLabelId !== 1 });

    // Needed for some GD Only css tweaks on mobile and phablet.
    const outsideLogoClasses = classnames('logo', {
      'brand-logo': privateLabelId === 1,
      'go-logo-anchor': privateLabelId === 1
    });

    // if pcx redirect to hub else redirect to vh redirect link
    let logoHref = urls.home.href;

    if (privateLabelId === 1) {
      if (pcx) {
        logoHref = urls.proHub.href;
      } else {
        logoHref = urls.vhLogoLink.href;
      }
    }

    const logo = (
      <Logo
        className={ outsideLogoClasses }
        country={ props.countryName }
        href={ logoHref }
        market={ market }
        messages={ messages }
        logos={ props.logos }
        privateLabelId={ privateLabelId }
        privateLabelName={ props.privateLabelName }
        showCountryNameOnLogo={ false }
        figClass={ logoClasses }
        urlArgs={ props.urlArgs }
        isMobile={ isMobile }
        tempLogo={ props.tempLogo }
        urls={ props.urls }
        data-eid='uxp.hyd.app_header.gd_logo.link.click'
      />
    );

    return (
      <div className='nav-left-wrap'>
        { orderId && <OrderConfirmation orderId={ orderId } /> }
        { isNotDesktop &&
          props.navigation &&
          !!(props.navigation.length) && (
          <HamburgerMenu { ...props } items={ props.navigation } />
        ) }
        { logo }
        <div className='application-header-nav nav-left'>
          <AppName
            appName={ props.appName }
            app={ props.app }
            messages={ messages }
            market={ market }
            urlArgs={ props.urlArgs }
            urls={ props.urls }
            showWaffleCommerceLink={ props.features.showWaffleCommerceLink }
            showWaffleMenu={ props.showWaffleMenu }
            customWaffleLinks={ props.customWaffleLinks }
          />
        </div>
        { version && (
          <div className='version'>
            <Version value={ version } />
          </div>
        ) }
      </div>
    );
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      isMobile,
      isTablet,
      navigationTop
    } = this.props;

    // safari only stuff :(
    let isThisSafari = false;
    if (typeof navigator !== 'undefined') {
      isThisSafari =
        navigator.userAgent.indexOf('Safari') !== -1 &&
        navigator.userAgent.indexOf('Chrome') === -1;
    }

    return (
      <nav
        className={ classnames('nav-top', {
          'safari-only': isThisSafari
        }) }>
        <div className='application-header-container'>
          <div className='nav-top-flex'>
            { this.renderLeftNav() }
            <ul className='application-header-nav nav-right'>
              {!isMobile && !isTablet && navigationTop && (
                <li className='nav-top-item'>
                  <div>
                    <a
                      className='custom-link'
                      href={ navigationTop.href }
                      id={ navigationTop.id }
                      target={ navigationTop.target }
                      onClick={ navigationTop.onClick }
                      data-eid={ navigationTop.eid }>
                      { navigationTop.caption }
                    </a>
                  </div>
                </li>
              )}
              { !isMobile && !isTablet && navigationTop && (
                <li className='custom-links-divider' />
              ) }
              { this.renderGlobalElements() }
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

NavigationTop.update = function update(nav, done = () => {}) {
  if (Array.isArray(nav) && nav.length === 1) {
    return this.setState(
      {
        navigationTop: nav[0]
      },
      done
    );
  }
};

NavigationTop.updateHelpUrl = function updateHelpUrl(helpUrl, done = () => {}) {
  return this.setState(
    {
      helpUrl
    },
    done
  );
};

NavigationTop.updateCartUrl = function updateCartUrl(url, done = () => {}) {
  return this.setState(
    {
      cartUrl: url
    },
    done
  );
};

NavigationTop.updateCart = function updateCart(items, done = () => {}) {
  return this.setState(
    {
      items
    },
    done
  );
};

/**
 * Helper to update cart component.
 *
 * @param {React.ReactElement} component Updated React component.
 * @param {Function} done Continuation to call when complete.
 * @returns {state} state change
 * @api public
 */
NavigationTop.updateCartComponent = function updateCartComponent(
  component,
  done = () => {}
) {
  return this.setState(
    {
      CartComponent: component
    },
    done
  );
};

/**
 * Helper to update AccountTrayNav.
 *
 * @param {Object} props consists of updated account tray links, and a replaceAccountTrayNavLinks variable.
 * @param {Function} done Continuation to call when complete.
 * @returns {state} state change
 * @api public
 */
NavigationTop.updateAccountTrayNav = function updateAccountTrayNav(props, done = () => {}) {
  let { newNav = null, replaceAccountTrayNavLinks = false } = props;

  // If new nav is false/null that is handled in account tray.
  if (newNav && !newNav[0].caption) {
    newNav = null;
    replaceAccountTrayNavLinks = false;
    console.warn(`The provided customAccountTrayNav of "${this.props.customAccountTrayNav}"
    is not correct data (not iterable/missing a caption). Please see the Application-header-legacy's
    readme. The default account tray nav links will be used.`);
  }

  return this.setState(
    {
      customAccountTrayNav: newNav,
      replaceAccountTrayNavLinks: replaceAccountTrayNavLinks
    },
    done
  );
};

/**
 * Helper to update waffle menu links.
 *
 * @param {React.ReactElement} component Updated React component.
 * @param {Function} done Continuation to call when complete.
 * @returns {state} state change
 * @api public
 */

NavigationTop.updateWaffleLinks = function updateWaffleLinks(customWaffleLinks, done = () => {}) {
  if (customWaffleLinks && (Array.isArray(customWaffleLinks.topLinks) || Array.isArray(customWaffleLinks.quickLinks))) {
    return this.setState(
      {
        customWaffleLinks: customWaffleLinks
      },
      done
    );
  }
};

/**
 * Default properties for the top half of the navigation.
 *
 * @type {Object}
 * @api public
 */
NavigationTop.defaultProps = {
  app: 'domainmanager',
  features: {},
  preset: 'application-header',
  loggedIn: false,
  manifest: 'applicationheader',
  market: 'en-US',
  privateLabelId: 1,
  privateLabelName: 'GoDaddy.com',
  proshopper: false,
  urlArgs: {},
  sso: {
    app: 'www',
    path: ''
  },
  urls: {
    notifications: {
      api: {
        href: 'https://mya.dev-godaddy.com/webapi/notifications'
      },
      cache: {
        href: '//img1.wsimg-com.ide/mya/notifications/cache.html'
      },
      endpoint: {
        href: 'https://notifications.api.cloud.dev-godaddy.com/v1/notifications'
      }
    },
    help: {
      href: 'https://www.godaddy.com/help'
    },
    home: {
      href: 'https://www.godaddy.com'
    },
    contactUs: {
      href: 'https://www.godaddy.com/contact-us.aspx'
    },
    checkout: {
      href: 'https://cart.godaddy.com'
    },
    renewals: {
      href: 'https://account.godaddy.com/billing?filter=expires&subFilter=90'
    },
    account: {
      href: 'https://account.godaddy.com'
    },
    products: {
      href: 'https://account.godaddy.com/products'
    },
    pro: {
      href: 'https://pro.godaddy.com/launch/managewp?landingScreen=pro-home'
    },
    proHomeApp: {
      href: 'https://pro.godaddy.com/launch/managewp?landingScreen=pro-home'
    }
  }
};

/**
 * Require properties.
 *
 * @type {Object}
 * @api public
 */
NavigationTop.propTypes = {
  account: PropTypes.string,
  app: PropTypes.string.isRequired,
  appName: PropTypes.string.isRequired,
  className: PropTypes.string,
  CartComponent: PropTypes.elementType,
  cartUrl: PropTypes.string,
  countryName: PropTypes.string,
  customWaffleLinks: PropTypes.object,
  displayName: PropTypes.string,
  env: PropTypes.string,
  features: PropTypes.object,
  gdLogoExp: PropTypes.string,
  help: PropTypes.string,
  helpUrl: PropTypes.string,
  hidden: PropTypes.string,
  preset: PropTypes.string,
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool,
  items: PropTypes.number,
  loggedIn: PropTypes.bool,
  logos: PropTypes.object,
  manifest: PropTypes.string,
  market: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  name: PropTypes.string,
  navigation: PropTypes.array,
  navigationTop: PropTypes.object,
  orderId: PropTypes.string,
  pcx: PropTypes.bool,
  privateLabelId: PropTypes.number,
  privateLabelType: PropTypes.number,
  privateLabelName: PropTypes.string,
  products: PropTypes.string,
  proshopper: PropTypes.bool,
  shopperId: PropTypes.string,
  showWaffleMenu: PropTypes.bool,
  sidekickDisabled: PropTypes.bool,
  renewals: PropTypes.string,
  resellerUrls: PropTypes.shape({
    help: PropTypes.string
  }),
  sso: PropTypes.object,
  tempLogo: PropTypes.string,
  urlArgs: PropTypes.object,
  urls: PropTypes.object.isRequired,
  version: PropTypes.string,
  vip: PropTypes.object
};

export { Version };
